<template>
  <div id="calls">

    <header>
      <img src="../assets/images/new-image/concorsando-white.png" alt="Logo" class="logo">
    </header>

    <div class="container">
      <p>Ciao, abbiamo notato che non hai ancora l'applicazione installata, vuoi procedere?</p>
      <button @click="conferma()">Installa ora!</button>
    </div>


  </div>
</template>

<script>
import 'bootstrap'
import {routesEnums, Storage} from "../helpers/enums";
import {mobileCheck} from "../lib/mobileCheck";

export default {
  name: "Fallback",
  data() {
    return {
      link: null,
      appAppleStore: null,
      appAndroidStore: null,
      appHuaweiStore: null
    }
  },
  components: {},
  mounted() {
    this.link = this.$route?.params?.link
    const isMobile = mobileCheck()
    if (!this.link || !isMobile) {
      this.$router.push({name: routesEnums.HOME}).catch(() => {})
    }
    this.appAppleStore = process.env.VUE_APP_APPLE_STORE
    this.appAndroidStore = process.env.VUE_APP_ANDROID_STORE
    this.appHuaweiStore = process.env.VUE_APP_HUAWEI_STORE
  },
  methods: {
    conferma() {
      if (confirm('Confermi di voler fare il download dell\'app?')) {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if ((/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
          this.copiaTestoNelClipboard(this.link);
          sessionStorage.removeItem(Storage.URL_FALLBACK);
          window.location = this.appAppleStore;
        } else if (/android/i.test(userAgent)) {
          sessionStorage.removeItem(Storage.URL_FALLBACK);
          this.link = this.link.replaceAll("&","%26");
          window.location = this.appAndroidStore + '&url=' + this.link
        }
      }
    },
    copiaTestoNelClipboard(testo) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(testo).then(() => {
        }).catch((err) => {
          console.error(err)
        });
      }
    }
  }
}
</script>
<style>

header {
  background-color: #007bff;
  color: #ffffff;
  padding: 20px;
  text-align: center;
}

.container {
  padding: 20px;
  text-align: center;
}

img.logo {
  max-width: 100%;
  height: auto;
}

button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}
</style>
